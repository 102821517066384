import etg from "../../assets/etg.jpg";
import demod from "../../assets/demod.jpg";
import utasr from "../../assets/utasr.jpg";
import filler from "../../assets/filler.jpg";
import seek from "../../assets/seek.jpg";
import neurotech from "../../assets/neurotech.jpg";
import pong from "../../assets/pong.jpg";
import emojify from "../../assets/emojify.jpg";

export const projectsData = [
    {
        id: 1,
        image: etg,
        title: 'ETG Commodities ML Optimization',
        category: 'software',
    },
    {
        id: 2,
        image: demod,
        title: 'Radio Demodulator PCB Design',
        category: 'hardware',
    },
    {
        id: 3,
        image: utasr,
        title: 'RC Car Build & Integration',
        category: 'hardware',
    },
    {
        id: 4,
        image: filler,
        title: 'Arms Assembly Filler Game',
        category: 'embedded systems',
    },
    {
        id: 5,
        image: seek,
        title: 'RSX Seek 1st Place',
        category: 'embedded systems',
    },
    {
        id: 6,
        image: neurotech,
        title: 'Neuroprosthetic Arm',
        category: 'hardware',
    },
    {
        id: 7,
        image: pong,
        title: 'Verilog Pong Game',
        category: 'embedded systems',
    },
    {
        id: 8,
        image: emojify,
        title: 'Face Emojification ML Model',
        category: 'software',
    },

];

export const projectsNav = [
    {
        name: 'all',
    },
    {
        name: 'software',
    },
    {
        name: 'embedded systems',
    },
    {
        name: 'hardware',
    },
];