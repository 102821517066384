import React from 'react'
import "./experience.css";
import Software from './Software';
import Hardware from './Hardware';

const Experience = () => {
    return (
        <section className="experience section" id="experience">
            <h2 className="section__title">Experience</h2>
            <span className="section__subtitle">My technical level</span>

            <div className="skills__container container grid">
                <Software />
                <Hardware />
            </div>
        </section>
    )
}

export default Experience