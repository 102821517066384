import React from 'react';
import "./App.css";
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Qualifications from './components/qualifications/Qualifications';
import Resumes from './components/resumes/Resumes';
import Portfolio from './components/portfolio/Portfolio';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Scrollup from './components/scrollup/Scrollup';

const App = () => {
  return (
    <>
      <Header />

      <main className='main'>
        <Home />
        <About />
        <Experience />
        <Qualifications />
        <Resumes />
        <Portfolio />
        <Contact />
      </main>

      <Footer />
      <Scrollup />
    </>
  )
}

export default App