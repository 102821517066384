import React from 'react';
import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Luna Sun</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">About</a>
                    </li>

                    <li>
                        <a href="#portfolio" className="footer__link">Projects</a>
                    </li>

                    <li>
                        <a href="#contact" className="footer__link">Contact Me</a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a href="https://www.linkedin.com/in/luna-sun/" className="footer__social-link" target="_blank">
                        <i class="bx bxl-linkedin"></i>
                    </a>

                    <a href="https://github.com/luna49" className="footer__social-link" target="_blank">
                        <i class="bx bxl-github"></i>
                    </a>

                    <a href="mailto:lunasun918@gmail.com" className="footer__social-link" target="_blank">
                        <i class="bx bx-envelope"></i>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer