import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/luna-sun/" className="home__social-icon" target="_blank">
                <i class="uil uil-linkedin"></i>
            </a>

            <a href="https://github.com/luna49" className="home__social-icon" target="_blank">
                <i class="uil uil-github"></i>
            </a>

            <a href="mailto:lunasun918@gmail.com" className="home__social-icon" target="_blank">
                <i class="uil uil-envelope"></i>
            </a>
        </div>
    )
}

export default Social